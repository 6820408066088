import * as React from "react";
import { injectIntl } from "gatsby-plugin-intl";
import { FC, useState } from "react";
import Pagination from "../Pagination";
import { useQuery } from "@apollo/client";
import { GET_ANALYSIS_BY_TYPE } from "../../query/getAnalysis";
import AllAnalysisTableRow from "../analysisAndPrices/AllAnalysisTableRow";
import SearchItem from "./SearchItem";
import ListItem from "./ListItem";
// @ts-ignore

type AllAnalysisTableProps = {
  intl: any;
  analysisType: any;
  searchTerm: any;
};

const HouseAnalysisTable: FC<AllAnalysisTableProps> = ({
  intl,
  analysisType,
  searchTerm,
  setCost,
  selectedItems,
  setSelectedItems,
  makeEnable,
}) => {
  const [page, setPage] = useState(0);

  const handleAddItem = (analysisId: any) => {
    // Add the selected item to the state
    setSelectedItems((prevSelectedItems) => [...prevSelectedItems, analysisId]);
  };

  const handleRemoveItem = (analysisId: any) => {
    // Remove the selected item from the state
    setSelectedItems((prevSelectedItems) =>
      prevSelectedItems.filter((id) => id !== analysisId)
    );
  };

  const pageSize = 20;
  const variables = {
    ...(analysisType || searchTerm
      ? {
          filters: {
            ...(searchTerm
              ? {
                  or: [
                    {
                      description: {
                        containsi: searchTerm,
                      },
                    },
                    {
                      title: {
                        containsi: searchTerm,
                      },
                    },
                  ],
                }
              : {}),
            homeVisit: { eq: true },
          },
        }
      : {}),
    pagination: {
      page,
      pageSize,
    },
    locale: intl.locale,
  };

  const { loading, error, data } = useQuery(GET_ANALYSIS_BY_TYPE, {
    fetchPolicy: "network-only",
    variables: variables,
  });

  if (loading) return intl.formatMessage({ id: "Loading.LoadingTitle" });

  if (error) return intl.formatMessage({ id: "Error.ErrorTitle" });

  return (
    <div>
      <div className=" flex flex-col pb-[1rem] px-[1.2rem] mb-[1rem] overflow-y-scroll h-[30rem] xl:w-[35rem] lg:w-[35rem] sm:w-[33rem] xs:w-[17rem] border border-[#001439] border-opacity-25 ">
        <div className="lg:w-full">
          <div>
            <div className="xs:mt-5 lg:w-full ">
              <div>
                {data?.analyses?.data?.map((analysis: any, index: any) => (
                  //   <AllAnalysisTableRow
                  //     analysisId={analysis.id}
                  //     title={analysis.attributes.title}
                  //     price={analysis.attributes.price}
                  //     material={analysis.attributes.analysis_material}
                  //     term={analysis.attributes.term}
                  //     key={`analyses-${index}`}
                  //   />
                  <SearchItem
                    analysisId={analysis.id}
                    title={analysis.attributes.title}
                    price={analysis.attributes.price}
                    material={analysis.attributes.analysis_material}
                    term={analysis.attributes.term}
                    key={`analyses-${index}`}
                    handleAddItem={handleAddItem}
                    handleRemoveItem={handleRemoveItem}
                    selectedItems={selectedItems}
                    setSelectedItems={setSelectedItems}
                  />
                ))}
              </div>
              <Pagination
                setPage={setPage}
                pagination={data.analyses.meta.pagination}
              />
            </div>
          </div>
        </div>
      </div>
      <div className=" mt-[1rem] flex flex-col  xl:w-[40rem] lg:w-[35rem] sm:w-[33rem] xs:w-[17rem] ">
        <ListItem
          selectedItems={selectedItems}
          data={data}
          setSelectedItems={setSelectedItems}
          setCost={setCost}
          makeEnable={makeEnable}
        />
      </div>
    </div>
  );
};

export default injectIntl(HouseAnalysisTable);
