import * as React from "react";

import Layout, { AppStateContext } from "../components/Layout/Layout";
//@ts-ignore
import Car from "../images/car.webm";
import parse from "html-react-parser";
//@ts-ignore
import Info from "../images/info.svg";
//@ts-ignore
import Calendar from "../images/calendar.svg";
//@ts-ignore
import Arrow from "../images/arrowDown_grey.svg";
//@ts-ignore
import { useCallback, useEffect, useState } from "react";
import Search from "../images/search.svg";
import "../components/analysisAndPrices/AnalysisAndPrices.css";
// @ts-ignore
import { Breadcrumb } from "gatsby-plugin-breadcrumb";
import debounce from "lodash.debounce";
import { injectIntl, navigate } from "gatsby-plugin-intl";
import BreadCrumbs from "../BreadCrumbs";
import { useRef } from "react";
import HouseAnalysisTable from "../components/houseCall/HouseAnalysisTable";
import { Field, Form, Formik, FormikHelpers, useFormikContext } from "formik";
import { gql, useMutation, useQuery } from "@apollo/client";
import { CREATE_ORDER } from "../query/createOrder";
import ApplicationSuccess from "../components/popups/ApplicationSuccess";
import * as Yup from "yup";
import { iinRegex, phoneNumberMask, phoneNumberRegex } from "../utils/regex";
import MaskedInput from "react-text-mask";
import ApplicationFailed from "../components/popups/ApplicationFailed";
import ResultSuccess from "../components/popups/ResultSuccess";
import ResultError from "../components/popups/ResultError";
//@ts-ignore

const houseCall = ({ intl, pageContext }) => {
  const validationSchema = Yup.object().shape({
    surname: Yup.string().required(
      intl.formatMessage({ id: "Validation.Required" })
    ),
    name: Yup.string().required(
      intl.formatMessage({ id: "Validation.Required" })
    ),
    iin: Yup.string()
      .required(intl.formatMessage({ id: "Validation.Required" }))
      .matches(iinRegex, intl.formatMessage({ id: "Validation.Format.Iin" })),
    phoneNumber: Yup.string()
      .required(intl.formatMessage({ id: "Validation.Required" }))
      .matches(
        phoneNumberRegex,
        intl.formatMessage({ id: "Validation.Required" })
      ),
    street: Yup.string().required(
      intl.formatMessage({ id: "Validation.Required" })
    ),
    date: Yup.string().required(
      intl.formatMessage({ id: "Validation.Required" })
    ),
  });
  const myKeysValues = typeof window !== "undefined" && window.location.search;

  const [analysisType, setAnalysisType] = useState(null);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showFailedPopup, setShowFailedPopup] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const [cost, setCost] = useState(null);
  const [searchTermValue, setSearchTermValue] = useState("");
  const [disable, setDisable] = useState(true);
  const [checked, setChecked] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const videoRef = useRef(null);
  const handleMouseOut = () => {
    // @ts-ignore
    videoRef.current.pause();
  };
  const handleMouseOver = () => {
    // @ts-ignore
    videoRef.current.play();
  };

  const makeEnable = () => {
    setChecked(!checked);
    if (!checked) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  };

  const [makeOrder] = useMutation(CREATE_ORDER);
  const handleSubmit = async (values: any, profile: any) => {
    const visitDate = new Date(`${values.date}T${values.time}:00.000Z`);
    visitDate.setHours(visitDate.getHours() - 6);
    try {
      const result = await makeOrder({
        variables: {
          input: {
            surname: values.surname,
            name: values.name,
            patronymic: values.middleName,
            visitDate: visitDate.toISOString(),
            // visitTime: values.time + ":00.000",
            iin: values.iin,
            phone: values.phoneNumber.replace(/\D/g, ""),
            homeVisit: true,
            cost: cost ? cost + 1500 : 1500,
            fullAddress:
              "улица " +
              values.street +
              " дом " +
              values.house +
              " квартира " +
              values.flat,
            analyses: selectedItems,
            payType: "nalichnye",
          },
        },
        onCompleted: (data) => {
          console.log(profile, values.time);
          setShowSuccessPopup(true);
          values.surname = "";
          values.name = "";
          values.middleName = "";
          values.iin = "";
          values.phoneNumber = "";
          values.street = "";
          values.house = "";
          values.flat = "";
          values.date = "";
          values.time = "";
          values.analysis = [];
        },
        onError: (error) => {
          setShowFailedPopup(true);
          // console.error(error.message);
        },
      });
      // Reset the form state
    } catch (error) {
      setShowFailedPopup(true);
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchTerm) {
        setAnalysisType(null);
      }
      // Send Axios request here
    }, 1500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  useEffect(() => {
    if (myKeysValues !== null) {
      const urlParams = new URLSearchParams(myKeysValues);
      if (urlParams.get("search") !== null) {
        setSearchTermValue(String(urlParams.get("search")) || "");
        setSearchTerm(String(urlParams.get("search")) || "");
      }
    }
  }, [myKeysValues]);

  const handleSearchTermChange = (e: any) => {
    e.preventDefault();
    setSearchTerm(e.target.value);
  };

  const debouncedChangeHandler = useCallback(
    debounce(handleSearchTermChange, 300),
    []
  );

  return (
    <Layout>
      <>
        <>
          <div className="flex items-center justify-center bg-[#FAFAFA] ">
            <div className="mb-[10rem] flex flex-col  xl:max-w-[1200px] xl:w-[1200px] lg:max-w-[1000px] lg:w-[1000px] sm:max-w-[680px] sm:w-[680px] xs:max-w-[300px] xs:w-[300px]">
              <div className="ml-[0.1rem] mt-[2.5rem] mb-[2.5rem] ">
                <BreadCrumbs
                  pageContext={pageContext}
                  title="ServiceMenu.HouseCall.HouseCallHeader"
                />
              </div>
              <h2 className=" text-[2rem] font-semibold text-[#001439]">
                {intl.formatMessage({
                  id: "ServiceMenu.HouseCall.HouseCallHeader",
                })}
              </h2>
              <div className="cursor-pointer flex sm:flex-row xs:flex-col sm:justify-evenly xs:justify-center xs:items-center mt-[2rem] w-auto h-auto   lg:gap-[2rem] sm:gap-[1rem] bg-[#EDF2FC] rounded-[1rem] ">
                <video
                  className="mt-[1.5rem] lg:w-[15rem] lg:h-[15rem] sm:w-[16.5rem] sm:h-[13rem] xs:w-[12.5rem] xs:h-[10rem]"
                  ref={videoRef}
                  onMouseOver={handleMouseOver}
                  onMouseOut={handleMouseOut}
                  muted
                >
                  <source
                    src="../images/car_safari.mov"
                    type='video/mov; codecs="hvc1"'
                  />
                  <source src={Car} type="video/webm;" />
                </video>
                <div className="sm:w-[45%] xs:w-[100%] grid py-[1.5rem] sm:pr-[2rem] xs:pl-[0.5rem]">
                  <h2 className="sm:text-[1.75rem] xs:text-[1.125rem] font-semibold sm:leading-[2rem] xs:leading-[1.4rem]">
                    {intl.formatMessage({
                      id: "ServiceMenu.HouseCall.Banner.Title",
                    })}
                  </h2>
                  <p className="sm:mt-[1rem] xs:mt-[0.5rem] sm:text-[1.125rem] xs:text-[1rem] font-medium">
                    {intl.formatMessage({
                      id: "ServiceMenu.HouseCall.Banner.Subtitle",
                    })}
                  </p>
                  <p className="sm:mt-[1rem] xs:mt-[0.5rem] sm:text-[0.875rem] xs:text-[0.75rem] font-regular">
                    {intl.formatMessage({
                      id: "ServiceMenu.HouseCall.Banner.Description",
                    })}
                  </p>
                </div>
              </div>
              <AppStateContext.Consumer>
                {({ profile }) => (
                  <Formik
                    initialValues={{
                      surname: (profile && profile.surname) || "",
                      name: (profile && profile.name) || "",
                      middleName: (profile && profile.patronymic) || "",
                      iin: (profile && profile.iin) || "",
                      phoneNumber:
                        profile && profile.username
                          ? profile.username
                              .replace(/^\+7\s*/, "")
                              .replace(/\D/g, "")
                              .replace(
                                /^(\d{3})(\d{3})(\d{2})(\d{2})$/,
                                "+7 ($1) $2 $3 $4"
                              )
                          : "" || "",
                      street:
                        (profile &&
                          profile.fullAddress &&
                          profile.fullAddress.split(" ")[0]) ||
                        "",
                      house:
                        (profile &&
                          profile.fullAddress &&
                          profile.fullAddress.split(" ")[1]) ||
                        "",
                      flat:
                        (profile &&
                          profile.fullAddress &&
                          profile.fullAddress.split(" ")[2]) ||
                        "",
                      date: "",
                      time: "",
                      analysis: [],
                    }}
                    validate={() => {
                      const errors = {
                        surname: "",
                        name: "",
                        iin: "",
                        phoneNumber: "",
                        street: "",
                        house: "",
                        date: "",
                        time: "",
                        analysis: "",
                      };
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                      handleSubmit(values);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleSubmit,
                      handleBlur,
                      isSubmitting,
                      handleChange,
                    }) => (
                      <form>
                        <div className="flex flex-col xs:flex-col bg-white rounded-[1rem] w-auto pt-[2rem] lg:pl-[1.5rem] xs:pl-[1.2rem] pb-[2.5rem]  sm:mt-[2rem] xs:mt-[1rem]">
                          <div className="flex flex-wrap  ">
                            <div className="grid grid-flow-row ">
                              <p className="font-medium text-[1rem]">
                                {parse(
                                  intl.formatMessage({
                                    id: "Registration.Form.Surname",
                                  })
                                )}
                                <span className="text-red-600">*</span>
                              </p>
                              <input
                                type="text"
                                placeholder="Фамилия"
                                id="surname"
                                name="surname"
                                value={values.surname}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className=" pl-[1rem] px-[0.8rem] mt-[0.875rem] lg:w-[20rem] sm:w-[17rem] xs:w-[16.5rem] h-[3rem] border rounded-[0.5rem]"
                              />
                              <div style={{ marginTop: "0.5rem" }}>
                                {errors.surname && touched.surname && (
                                  <div className="text-red-600">
                                    {errors.surname}
                                  </div>
                                )}
                              </div>{" "}
                            </div>
                            <div className="grid grid-flow-row sm:pl-[2rem] sm:mt-[0rem] xs:mt-[1rem]">
                              <p className="font-medium text-[1rem]">
                                {parse(
                                  intl.formatMessage({
                                    id: "Registration.Form.Name",
                                  })
                                )}
                                <span className="text-red-600">*</span>
                              </p>
                              <input
                                type="text"
                                id="name"
                                name="name"
                                value={values.name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Имя"
                                className=" pl-[1rem] px-[0.8rem] mt-[0.875rem] lg:w-[20rem] sm:w-[17rem] xs:w-[16.5rem] h-[3rem] border rounded-[0.5rem]"
                              />
                              <div style={{ marginTop: "0.5rem" }}>
                                {errors.name && touched.name && (
                                  <div className="text-red-600">
                                    {errors.name}
                                  </div>
                                )}
                              </div>{" "}
                            </div>
                            <div className="grid grid-flow-row xl:pl-[2rem] xl:mt-[0rem] xs:mt-[1rem]">
                              <p className="font-medium text-[1rem]">
                                {parse(
                                  intl.formatMessage({
                                    id: "Registration.Form.MiddleName",
                                  })
                                )}
                              </p>
                              <input
                                type="text"
                                id="middleName"
                                name="middleName"
                                placeholder="Отчество"
                                value={values.middleName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className=" pl-[1rem] px-[0.8rem] mt-[0.875rem] lg:w-[20rem] sm:w-[17rem] xs:w-[16.5rem] h-[3rem] border rounded-[0.5rem]"
                              />
                            </div>
                          </div>
                          <div className="mt-[1rem] flex flex-wrap ">
                            <div className="grid grid-flow-row ">
                              <p className="font-medium text-[1rem]">
                                {parse(
                                  intl.formatMessage({
                                    id: "Registration.Form.IIN",
                                  })
                                )}{" "}
                                <span className="text-red-600">*</span>
                              </p>
                              <input
                                type="text"
                                id="iin"
                                name="iin"
                                placeholder="Ваш ИИН"
                                value={values.iin}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={`pl-[1rem] px-[0.8rem] mt-[0.875rem] lg:w-[20rem] sm:w-[17rem] xs:w-[16.5rem] h-[3rem] border rounded-[0.5rem]`}
                              />
                              <div style={{ marginTop: "0.5rem" }}>
                                {errors.iin && touched.iin && (
                                  <div className="text-red-600">
                                    {errors.iin}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="grid grid-flow-row sm:pl-[2rem] sm:mt-[0rem] xs:mt-[1rem]">
                              <p className="font-medium text-[1rem]">
                                {parse(
                                  intl.formatMessage({
                                    id: "Registration.Form.PhoneNumber",
                                  })
                                )}{" "}
                                <span className="text-red-600">*</span>
                              </p>
                              <Field
                                name="phoneNumber"
                                render={({ field }) => (
                                  <MaskedInput
                                    type="text"
                                    id="phoneNumber"
                                    name="phoneNumber"
                                    value={
                                      profile && profile.username
                                        ? profile.username
                                            .replace(/^\+7\s*/, "")
                                            .replace(/\D/g, "")
                                            .replace(
                                              /^(\d{3})(\d{3})(\d{2})(\d{2})$/,
                                              "+7 ($1) $2 $3 $4"
                                            )
                                        : ""
                                    }
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    placeholder=" +7 XXX XXXXXXX"
                                    className={`pl-[1rem] px-[0.8rem] mt-[0.875rem] lg:w-[20rem] sm:w-[17rem] xs:w-[16.5rem] h-[3rem] border rounded-[0.5rem]`}
                                    {...field}
                                    mask={phoneNumberMask}
                                    showMask="true"
                                  />
                                )}
                              />
                              <div style={{ marginTop: "0.5rem" }}>
                                {errors.phoneNumber && touched.phoneNumber && (
                                  <div className="text-red-600">
                                    {errors.phoneNumber}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="mt-[1rem] flex flex-wrap ">
                            <div className="grid grid-flow-row ">
                              <p className="font-medium text-[1rem]">
                                {parse(
                                  intl.formatMessage({
                                    id: "ServiceMenu.HouseCall.Form.Address",
                                  })
                                )}
                                <span className="text-red-600 ml-[0.3rem]">
                                  *
                                </span>
                              </p>
                              <input
                                type="text"
                                id="street"
                                name="street"
                                placeholder="Улица"
                                value={values.street}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                className=" pl-[1rem] px-[0.8rem] mt-[0.875rem] lg:w-[20rem] sm:w-[17rem] xs:w-[16.5rem] h-[3rem] border rounded-[0.5rem]"
                              />
                              <div style={{ marginTop: "0.5rem" }}>
                                {errors.street && touched.street && (
                                  <div className="text-red-600">
                                    {errors.street}
                                  </div>
                                )}
                              </div>{" "}
                            </div>
                            <div className="flex sm:mt-[1.7rem] xs:mt-[1rem] ">
                              <input
                                type="text"
                                id="house"
                                name="house"
                                placeholder="Дом"
                                value={values.house}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                className="sm:ml-[2rem]  pl-[1rem] px-[0.8rem] mt-[0.875rem]  sm:w-[10rem] xs:w-[8rem] h-[3rem] border rounded-[0.5rem]"
                              />
                              {errors.house && touched.house && errors.street}
                              <input
                                type="text"
                                id="flat"
                                name="flat"
                                placeholder="Квартира"
                                value={values.flat}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                className="sm:ml-[2rem] xs:ml-[0.5rem] pl-[1rem] px-[0.8rem] mt-[0.875rem]  sm:w-[10rem] xs:w-[8rem] h-[3rem] border rounded-[0.5rem]"
                              />
                            </div>
                          </div>
                          <div className="relative">
                            <div className=" mt-[1rem] sm:w-[26rem] xs:w-[17rem] px-[0.5rem] py-[0.2rem] h-auto items-center flex  gap-3 bg-[#FF922E] bg-opacity-25 rounded-[0.25rem]">
                              <p className="font-medium text-[1rem] text-[#001439] opacity-75">
                                {intl.formatMessage({
                                  id: "ServiceMenu.HouseCall.CostHouseCall",
                                })}
                                <span className="pl-[0.5rem]">1500 тг</span>
                              </p>
                              <div className="tooltip sm:pr-[0rem] xs:pr-[0.5rem]">
                                <img
                                  src={Info}
                                  alt=""
                                  className="cursor-pointer sm:w-auto xs:w-[2.2rem]"
                                />

                                <span className="tooltiptext  text-[0.875rem] font-regular">
                                  {intl.formatMessage({
                                    id: "ServiceMenu.HouseCall.CostDependsOnAddress",
                                  })}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="mt-[1rem] flex flex-wrap ">
                            <div className="grid grid-flow-row ">
                              <p className="font-medium text-[1rem]">
                                {parse(
                                  intl.formatMessage({
                                    id: "ServiceMenu.HouseCall.Form.Date",
                                  })
                                )}
                                <span className="text-red-600">*</span>
                              </p>
                              <input
                                type="date"
                                id="data"
                                name="date"
                                placeholder="01/01/2022 "
                                value={values.date}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                className=" pl-[1rem] px-[0.8rem] mt-[0.875rem] w-[15rem] h-[3rem] border rounded-[0.5rem]"
                              />
                              <img
                                src={Calendar}
                                alt=""
                                className="calendar_houseCall cursor-pointer"
                                onClick={() =>
                                  document.getElementById("data")?.click()
                                }
                              />
                              <div style={{ marginTop: "-.5rem" }}>
                                {errors.date && touched.date && (
                                  <div className="text-red-600">
                                    {errors.date}
                                  </div>
                                )}
                              </div>{" "}
                            </div>
                            <div className=" grid-flow-row sm:pl-[2rem] sm:mt-[0rem] xs:mt-[0rem]">
                              <p className="font-medium text-[1rem]">
                                {parse(
                                  intl.formatMessage({
                                    id: "ServiceMenu.HouseCall.Form.Time",
                                  })
                                )}
                              </p>
                              <input
                                type="time"
                                id="time"
                                name="time"
                                placeholder="12:00"
                                min="00:00"
                                max="24:00"
                                value={values.time}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                className=" pl-[1rem] px-[0.8rem] mt-[0.875rem] w-[15rem] h-[3rem] border rounded-[0.5rem]"
                              />
                              <img
                                src={Arrow}
                                alt=""
                                className="arrow cursor-pointer"
                              />
                              <div style={{ marginTop: ".5rem" }}>
                                {errors.time && touched.time && (
                                  <div className="text-red-600">
                                    {errors.time}
                                  </div>
                                )}
                              </div>{" "}
                            </div>
                          </div>

                          <h4 className="font-semibold text-[1.25rem] text-[#001439] mt-[2.75rem]">
                            {intl.formatMessage({
                              id: "ServiceMenu.HouseCall.ChooseAnalysis",
                            })}
                          </h4>

                          <div className="mt-[1rem]">
                            <input
                              value={searchTermValue}
                              autoFocus
                              type="text"
                              autoComplete="off"
                              className="border border-2 rounded-[0.6rem] xl:w-[33 rem] lg:w-[35rem] sm:w-[33rem] xs:w-[17rem] h-[3rem] pl-[1rem]"
                              placeholder={intl.formatMessage({
                                id: "ServiceMenu.AnalysisAndPrices.Placeholder.Search",
                              })}
                              onChange={(e) => {
                                e.preventDefault();
                                setSearchTermValue(e.target.value);
                                debouncedChangeHandler(e);
                              }}
                            />
                            <img
                              src={Search}
                              alt=""
                              className="houseCallsearch"
                            />
                          </div>

                          <HouseAnalysisTable
                            analysisType={analysisType}
                            searchTerm={searchTerm}
                            setCost={setCost}
                            selectedItems={selectedItems}
                            setSelectedItems={setSelectedItems}
                            makeEnable={makeEnable}
                          />
                          {/* <Link to="/house-call"> */}
                          <AppStateContext.Consumer>
                            {({ profile, setShowLoginPopup }) => (
                              <button
                                onClick={(e) => {
                                  profile
                                    ? handleSubmit(profile)
                                    : setShowLoginPopup(true);
                                  e.preventDefault();
                                }}
                                className={
                                  "mt-[1.5rem] w-[13.8rem] h-[3rem] text-[1.25rem] rounded-[0.56rem] text-white font-semibold bg-[#618EE4]" +
                                  " " +
                                  `${
                                    disable ? "bg-sky-500/50" : "bg-[#618EE4]"
                                  }`
                                }
                                disabled={disable}
                              >
                                {parse(
                                  intl.formatMessage({
                                    id: "ServiceMenu.HouseCall.ApplyButton",
                                  })
                                )}
                              </button>
                            )}
                          </AppStateContext.Consumer>
                          {/* <button
                        className="mt-[1.5rem] w-[13.8rem] h-[3rem] text-[1.25rem] rounded-[0.56rem] text-white font-semibold bg-[#618EE4]"
                        type="submit"
                      >
                        {parse(
                          intl.formatMessage({
                            id: "ServiceMenu.HouseCall.ApplyButton",
                          })
                        )}
                      </button> */}
                          {/* </Link> */}
                        </div>
                      </form>
                    )}
                  </Formik>
                )}
              </AppStateContext.Consumer>
              <ResultSuccess
                isOpen={showSuccessPopup}
                onClose={() => setShowSuccessPopup(false)}
                text="Popup.ResultSuccess.ResultSuccessText"
              />
              <ResultError
                isOpen={showFailedPopup}
                onClose={() => setShowFailedPopup(false)}
                text="Popup.ApplicationError.ErrorText"
              />
            </div>
          </div>
        </>
      </>
    </Layout>
  );
};

export default injectIntl(houseCall);
