import { gql } from "@apollo/client";

export const CREATE_ORDER = gql`
  mutation makeOrder($input: OrderInput!) {
    makeOrder(data: $input) {
      data {
        id
        attributes {
          status
        }
      }
    }
  }
`;
