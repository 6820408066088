import * as React from "react";

import { injectIntl } from "gatsby-plugin-intl";
import parse from "html-react-parser";
import "./HouseCall.css";
import Cross from "../../images/cross_blue.svg";
import { useState } from "react";
import { moneyFormatFromNumber } from "../../utils/formatter";
let sum = 0;
// @ts-ignore
const ListItem = ({
  intl,
  selectedItems,
  data,
  setSelectedItems,
  setCost,
  makeEnable,
}) => {
  sum = 0;
  const filteredData = data?.analyses?.data.filter((analysis: any) => {
    return selectedItems.includes(analysis.id);
  });
  return (
    <div>
      {filteredData.map((analyses: any) => {
        sum += analyses.attributes.price; // Update the sum inside the map function
        setCost(sum);
        return (
          <div
            key={analyses.id}
            className="pl-[0.5rem] flex sm:flex-row xs:flex-col sm:gap-10 xs:gap-3 pt-[0.75rem] justify-between pb-[1rem] border border-b-[#618EE4] border-opacity-25 border-x-white border-t-white border-dotted"
          >
            <p className="sm:w-[50%] xs:w-[100%] font-medium text-[1rem]">
              {analyses.attributes.title}
            </p>
            <div className="flex sm:w-[55%] xs:w-[100%]">
              <h4 className="w-[55%] font-semibold text-[1.25rem]">
                {moneyFormatFromNumber(analyses.attributes.price)}
                <span className="ml-[4px]">₸</span>
              </h4>
              <div className="w-[45%] cursor-pointer flex gap-2 items-start">
                <img
                  src={Cross}
                  alt=""
                  className="mt-[0.3rem] w-[0.8rem] h-[0.9rem]"
                />
                <p
                  className="font-medium text-[1rem] text-[#618EE4]"
                  onClick={() => {
                    setSelectedItems((prevSelectedItems: any) =>
                      prevSelectedItems.filter(
                        (item: any) => item !== analyses.id
                      )
                    );
                  }}
                >
                  Удалить
                </p>
              </div>
            </div>
          </div>
        );
      })}
      <div className=" mt-[1rem] flex flex-col  xl:w-[40rem] lg:w-[35rem] sm:w-[33rem] xs:w-[17rem] ">
        <div className="pl-[0.5rem] flex sm:flex-row xs:flex-col  sm:gap-10 xs:gap-3 pt-[0.75rem] justify-between pb-[1rem] border border-b-[#618EE4] border-opacity-50 border-x-white border-t-white border-dotted">
          <p className="sm:w-[50%] xs:w-[100%] font-medium text-[1rem]">
            {intl.formatMessage({
              id: "ServiceMenu.HouseCall.TotalCost",
            })}
          </p>
          <div className="flex sm:w-[55%] xs:w-[100%] ">
            <h4 className="w-[55%]  font-semibold text-[1.25rem]">1 500 ₸</h4>
          </div>
        </div>
      </div>

      <div className="mt-[1rem] flex w-[100%] items-center   sm:gap-6 xs:gap-3  ">
        <input
          type="checkbox"
          className="sm:w-[2rem] sm:h-[2rem] xs:w-[4rem] xs:h-[4rem] rounded-[0.5rem] border border-[#001439] border-opacity-25"
          onClick={makeEnable}
        />
        <p className="font-medium text-[1rem] cursor-pointer">
          {parse(
            intl.formatMessage({
              id: "Registration.Form.Agreement",
            })
          )}
        </p>
      </div>

      <div className="flex sm:flex-row xs:flex-col">
        <h2 className="mt-[1rem] font-semibold text-[1.75rem] text-[#001439]">
          {intl.formatMessage({
            id: "ServiceMenu.HouseCall.Total",
          })}
        </h2>
        <h2 className="mt-[1rem] sm:ml-[0.8rem] font-semibold text-[1.75rem] text-[#001439]">
          {sum + 1500}
        </h2>
      </div>
    </div>
  );
};

export default injectIntl(ListItem);
