import * as React from "react";

import { injectIntl } from "gatsby-plugin-intl";
import parse from "html-react-parser";
import Car from "../../images/car.svg";
import { useState } from "react";
import Calendar from "../../images/calendar.svg";
import Arrow from "../../images/arrowDown_grey.svg";
import "./HouseCall.css";
import Search from "../../images/search.svg";
import Plus from "../../images/plus_blue.svg";
import Cross from "../../images/cross_blue.svg";
import Info from "../../images/info.svg";
import { moneyFormatFromNumber } from "../../utils/formatter";
import { add } from "date-fns";
// @ts-ignore
const SearchItem = ({
  intl,
  analysisId,
  title,
  price,
  material,
  term,
  key,
  handleAddItem,
  handleRemoveItem,
  selectedItems,
  setSelectedItems,
}) => {
  // const [addDelete, setAddDelete] = useState(true);
  return (
    <div className="flex sm:flex-row xs:flex-col mb-3 justify-between items-start pb-[1rem] border border-b-[#001439] border-opacity-25 border-x-white border-t-white">
      <p className="sm:sm:w-[50%] xs:w-[100%] xs:w-[100%]  font-medium text-[1rem]">
        {parse(title)}
      </p>
      <div className="flex sm:w-[55%] xs:w-[100%] ">
        <h4 className="w-[55%]  font-semibold text-[1.25rem]">
          {moneyFormatFromNumber(price)}
          <span className="ml-[4px]">₸</span>
        </h4>
        {!selectedItems.includes(analysisId) && (
          <div
            className="w-[45%] cursor-pointer flex gap-2 items-start"
            onClick={() => {
              handleAddItem(analysisId);
            }}
          >
            <img src={Plus} alt="" className="mt-[0.25rem] w-[1rem] h-[1rem]" />
            <p className="font-medium text-[1rem] text-[#618EE4]">Добавить</p>
          </div>
        )}
        {selectedItems.includes(analysisId) && (
          <div
            className="w-[45%] cursor-pointer flex gap-2 items-start"
            onClick={() => {
              handleRemoveItem(analysisId);
            }}
          >
            <img
              src={Cross}
              alt=""
              className="mt-[0.3rem] w-[0.8rem] h-[0.9rem]"
            />
            <p className="font-medium text-[1rem] text-[#618EE4]">Удалить</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default injectIntl(SearchItem);
